import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import Seo from "../components/App/Seo"
import OurProducts from "../components/Index/OurProducts"
import FooterLanding from "../components/App/FooterLanding"
import AdditionalContent from "../components/logistiksoftware/AdditionalContent"

const LogistiksoftwarePage = () => {
  return (
    <Layout>
      <Seo title={"Innovative Logistiksoftware für die Cloud und onPremise"}
           description={"Mit NETVERSYS optimieren Sie Ihren Versand und mit " +
           "NETSTORSYS sorgen Sie für optimale Prozesse im Lager."}
           image={"/images/og/logentis-main-og.png"}
      />
      <Navbar showNavigation={false} />
      <div className={"pt-130 pt-xl-5"}>
        <OurProducts withScreenshots={true}
                     title={"Logistiksoftware mit Cloudtechnologie"}
                     makeH1={true}
                     smallIcons={true} />
      </div>
      <AdditionalContent />
      <FooterLanding />
    </Layout>
  )
}


export default LogistiksoftwarePage
