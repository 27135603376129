import React from "react"
import additionalImage from "../../assets/images/logistiksoftware/undraw_task_list_6x9d.svg"

const AdditionalContent = () => {
  return (
    <section className="overview-area ptb-70 pt-4 bg-f1f8fb">
      <div className="container">
        <div className="overview-box">
          <div className="overview-content">
            <div className="content">
              <span className="sub-title">Vorsprung durch Innovation</span>
              <h2>Egal ob NETVERSYS oder NETSTORSYS</h2>
              <p>
                Unsere Logistiksoftware ist immer auf den neusten Stand der Technik. Weil wir nie aufhören
                Sie zu erneuern. Weil wir immer zu den Besten gehören wollen.
              </p>

              <div className="features-text">
                <h4><i className="flaticon-tick"/> Stetige Weiterentwicklung</h4>
                <p>NETVERSYS war schon webbasiert, als viele in der Logistik den Browser
                nur zum surfen im Internet genutzt haben. Trotzdem ist seit den ersten
                Webtechnologien so viel passiert, dass wir im Laufe der Zeit, den Code
                mehr als einmal erneuert haben.</p>
              </div>

              <div className="features-text">
                <h4><i className="flaticon-tick"/> Immer am Puls der Zeit</h4>
                <p>
                  Die Entscheidungen, welchen neuen Features in unsere Logistiksoftware
                  NETVERSYS und NETSTORSYS integriert werden, sind nie einfach. Doch
                  mit Hilfe unserer Erfahrungen und den Input unserer Kunden schaffen
                  wir eine gute Balane zwischen Stabilität und Innovation.
                </p>
              </div>
            </div>
          </div>

          <div className="overview-image">
            <div className="image">
              <img src={additionalImage} alt={"Unsere moderne Logistiksoftware"}/>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AdditionalContent
